"use strict";

var _interopRequireDefault = require("/root/.jenkins/workspace/192.168.8.202-yuandian-frontend-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
require("core-js/modules/es7.object.get-own-property-descriptors");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.object.keys");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.loadMenus = void 0;
var _defineProperty2 = _interopRequireDefault(require("/root/.jenkins/workspace/192.168.8.202-yuandian-frontend-dev/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js"));
var _routers = _interopRequireDefault(require("./routers"));
var _store = _interopRequireDefault(require("@/store"));
var _settings = _interopRequireDefault(require("@/settings"));
var _nprogress = _interopRequireDefault(require("nprogress"));
require("nprogress/nprogress.css");
var _auth = require("@/utils/auth");
var _menu = require("@/api/system/menu");
var _permission = require("@/store/modules/permission");
var _elementUi = require("element-ui");
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
_nprogress.default.configure({
  showSpinner: false
}); // NProgress Configuration

var whiteList = ["/login", "/retrieve", "/resetPass"]; // no redirect whitelist

_routers.default.beforeEach(function (to, from, next) {
  if (to.meta.title) {
    document.title = to.meta.title + " - " + _settings.default.title;
  }
  _nprogress.default.start();
  if ((0, _auth.getToken)()) {
    // 已登录且要跳转的页面是登录页
    if (to.path === "/login") {
      next({
        path: "/"
      });
      _nprogress.default.done();
    } else {
      if (_store.default.getters.roles.length === 0) {
        // 判断当前用户是否已拉取完user_info信息
        _store.default.dispatch("GetInfo").then(function () {
          // 拉取user_info
          // 动态路由，拉取菜单
          loadMenus(next, to);
        }).catch(function () {
          _store.default.dispatch("LogOut").then(function () {
            location.reload(); // 为了重新实例化vue-router对象 避免bug
          });
        });
        // 登录时未拉取 菜单，在此处拉取
      } else if (_store.default.getters.loadMenus) {
        // 修改成false，防止死循环
        _store.default.dispatch("updateLoadMenus");
        loadMenus(next, to);
      } else {
        next();
      }
    }
  } else {
    /* has no token*/
    if (whiteList.indexOf(to.path) !== -1) {
      // 在免登录白名单，直接进入
      next();
    } else {
      next("/login?redirect=".concat(to.fullPath)); // 否则全部重定向到登录页
      _nprogress.default.done();
    }
  }
});
var loadMenus = function loadMenus(next, to) {
  (0, _menu.buildMenus)().then(function (res) {
    if (res && res.list && res.list.length > 0) {
      var sdata = JSON.parse(JSON.stringify(res.list));
      var rdata = JSON.parse(JSON.stringify(res.list));
      var sidebarRoutes = (0, _permission.filterAsyncRouter)(sdata);
      var rewriteRoutes = (0, _permission.filterAsyncRouter)(rdata, false, true);
      rewriteRoutes.push({
        path: "*",
        redirect: "/404",
        hidden: true
      });
      _store.default.dispatch("GenerateRoutes", rewriteRoutes).then(function () {
        // 存储路由
        _routers.default.addRoutes(rewriteRoutes); // 动态添加可访问路由表
        next(_objectSpread(_objectSpread({}, to), {}, {
          replace: true
        }));
      });
      _store.default.dispatch("SetSidebarRouters", sidebarRoutes);
    } else {
      _elementUi.Notification.error({
        title: "菜单获取失败",
        duration: 5000
      });
      _store.default.dispatch("LogOut").then(function () {
        location.reload(); // 为了重新实例化vue-router对象 避免bug
      });
    }
  });
};
exports.loadMenus = loadMenus;
_routers.default.afterEach(function () {
  _nprogress.default.done(); // finish progress bar
});