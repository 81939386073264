"use strict";

var _interopRequireDefault = require("/root/.jenkins/workspace/192.168.8.202-yuandian-frontend-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es7.array.includes");
require("core-js/modules/es6.string.includes");
var _store = _interopRequireDefault(require("@/store"));
/**
 * @param {Array} value
 * @returns {Boolean}
 * @example see @/views/permission/directive.vue
 */var _default = {
  install: function install(Vue) {
    Vue.prototype.checkPer = function (value) {
      if (value && value instanceof Array && value.length > 0) {
        var permissions = _store.default.getters && _store.default.getters.permissions;
        return permissions.some(function (permission) {
          return value.includes(permission);
        });
      } else {
        console.error("need roles! Like v-permission=\"['admin','editor']\"");
        return false;
      }
    };
  }
};
exports.default = _default;