"use strict";

var _interopRequireDefault = require("/root/.jenkins/workspace/192.168.8.202-yuandian-frontend-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getInfo = getInfo;
exports.login = login;
exports.logout = logout;
var _request = _interopRequireDefault(require("@/utils/request"));
function login(username, password, uuid) {
  return (0, _request.default)({
    url: 'auth/login',
    method: 'post',
    data: {
      username: username,
      password: password,
      // code,
      uuid: uuid
    }
  });
}
function getInfo() {
  return (0, _request.default)({
    url: 'auth/info',
    method: 'get'
  });
}

// export function getCodeImg() {
//   return request({
//     url: 'auth/code',
//     method: 'get'
//   })
// }

function logout() {
  return (0, _request.default)({
    url: 'auth/logout',
    method: 'delete'
  });
}