"use strict";

var _interopRequireDefault = require("/root/.jenkins/workspace/192.168.8.202-yuandian-frontend-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es7.array.includes");
require("core-js/modules/es6.string.includes");
var _store = _interopRequireDefault(require("@/store"));
var _default = {
  inserted: function inserted(el, binding) {
    var value = binding.value;
    var permissions = _store.default.getters && _store.default.getters.permissions;
    if (value && value instanceof Array) {
      if (value.length > 0) {
        var hasPermission = permissions.some(function (permission) {
          return value.includes(permission);
        });
        if (!hasPermission) {
          el.parentNode && el.parentNode.removeChild(el);
        }
      }
    } else {
      throw new Error("\u4F7F\u7528\u65B9\u5F0F\uFF1A v-permission=\"['admin','editor']\"");
    }
  }
};
exports.default = _default;