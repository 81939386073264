"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.setUserInfo = exports.logOut = exports.default = void 0;
var _login = require("@/api/login");
var _auth = require("@/utils/auth");
var user = {
  state: {
    token: (0, _auth.getToken)(),
    user: {},
    roles: [],
    permissions: [],
    // 第一次加载菜单时用到
    loadMenus: false
  },
  mutations: {
    SET_TOKEN: function SET_TOKEN(state, token) {
      state.token = token;
    },
    SET_USER: function SET_USER(state, user) {
      state.user = user;
    },
    SET_ROLES: function SET_ROLES(state, roles) {
      state.roles = roles;
    },
    SET_PERMISSIONS: function SET_PERMISSIONS(state, permissions) {
      state.permissions = permissions;
    },
    SET_LOAD_MENUS: function SET_LOAD_MENUS(state, loadMenus) {
      state.loadMenus = loadMenus;
    }
  },
  actions: {
    // 登录
    Login: function Login(_ref, userInfo) {
      var commit = _ref.commit;
      var rememberMe = userInfo.rememberMe;
      return new Promise(function (resolve, reject) {
        (0, _login.login)(userInfo.username, userInfo.password, userInfo.code, userInfo.uuid).then(function (res) {
          console.log(res.user, "user");
          (0, _auth.setToken)(res.token, rememberMe);
          commit('SET_TOKEN', res.token);
          setUserInfo(res.user, commit);
          // 第一次加载菜单时用到， 具体见 src 目录下的 permission.js
          commit('SET_LOAD_MENUS', true);
          resolve(res);
        }).catch(function (error) {
          reject(error);
        });
      });
    },
    // 获取用户信息
    GetInfo: function GetInfo(_ref2) {
      var commit = _ref2.commit;
      return new Promise(function (resolve, reject) {
        (0, _login.getInfo)().then(function (res) {
          setUserInfo(res.userInfo, commit);
          resolve(res);
        }).catch(function (error) {
          reject(error);
        });
      });
    },
    // 登出
    LogOut: function LogOut(_ref3) {
      var commit = _ref3.commit;
      return new Promise(function (resolve, reject) {
        (0, _login.logout)().then(function (res) {
          logOut(commit);
          resolve();
        }).catch(function (error) {
          logOut(commit);
          reject(error);
        });
      });
    },
    updateLoadMenus: function updateLoadMenus(_ref4) {
      var commit = _ref4.commit;
      return new Promise(function (resolve, reject) {
        commit('SET_LOAD_MENUS', false);
      });
    }
  }
};
var logOut = function logOut(commit) {
  commit('SET_TOKEN', '');
  commit('SET_ROLES', []);
  commit('SET_PERMISSIONS', []);
  (0, _auth.removeToken)();
};
exports.logOut = logOut;
var setUserInfo = function setUserInfo(res, commit) {
  // 如果没有任何权限，则赋予一个默认的权限，避免请求死循环
  commit('SET_ROLES', res.roleList);
  commit('SET_USER', res);
  commit('SET_PERMISSIONS', res.permissionList);
};
exports.setUserInfo = setUserInfo;
var _default = user;
exports.default = _default;