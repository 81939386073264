"use strict";

var _interopRequireDefault = require("/root/.jenkins/workspace/192.168.8.202-yuandian-frontend-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.constantRouterMap = void 0;
var _vue = _interopRequireDefault(require("vue"));
var _vueRouter = _interopRequireDefault(require("vue-router"));
var _index = _interopRequireDefault(require("../layout/index"));
_vue.default.use(_vueRouter.default);
var constantRouterMap = [{
  path: "/login",
  meta: {
    title: "登录",
    noCache: true
  },
  component: function component(resolve) {
    return require(["@/views/login"], resolve);
  },
  hidden: true
}, {
  path: "/404",
  component: function component(resolve) {
    return require(["@/views/features/404"], resolve);
  },
  hidden: true
}, {
  path: "/401",
  component: function component(resolve) {
    return require(["@/views/features/401"], resolve);
  },
  hidden: true
}, {
  path: "/retrieve",
  component: function component(resolve) {
    return require(["@/views/retrieve"], resolve);
  },
  hidden: true
}, {
  path: "/resetPass",
  component: function component(resolve) {
    return require(["@/views/resetPass"], resolve);
  },
  hidden: true
}, {
  path: "/",
  component: _index.default,
  redirect: "/dashboard",
  children: [{
    path: "dashboard",
    component: function component(resolve) {
      return require(["@/views/home"], resolve);
    },
    name: "Dashboard",
    meta: {
      title: "首页",
      icon: "index",
      affix: true,
      noCache: true
    }
  }]
}, {
  path: "/user",
  component: _index.default,
  hidden: true,
  redirect: "noredirect",
  children: [{
    path: "center",
    component: function component(resolve) {
      return require(["@/views/system/user/center"], resolve);
    },
    name: "个人中心",
    meta: {
      title: "个人中心"
    }
  }]
}];
exports.constantRouterMap = constantRouterMap;
var _default = new _vueRouter.default({
  // mode: 'hash',
  mode: "history",
  scrollBehavior: function scrollBehavior() {
    return {
      y: 0
    };
  },
  // base: process.env.BASE_URL,
  routes: constantRouterMap
});
exports.default = _default;